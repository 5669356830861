import { AxiosRequestConfig } from "axios";
import { httpClient } from "./AppConfig";

// TODO 공통 response 정의
export type ApiResponse<T> = T & T[];
export type ApiRequest<T = unknown> = T;

export const getAll = async <ApiRequest,>(
  url: string,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.get<ApiResponse<ApiRequest>>(url, {
    ...config,
  });
  return response.data;
};

export const get = async <ApiRequest,>(
  url: string,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.get<ApiResponse<ApiRequest>>(url, {
    ...config,
  });
  return response.data;
};

export const post = async <ApiRequest,>(
  url: string,
  data: ApiRequest,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.post<ApiResponse<ApiRequest>>(url, data, {
    ...config,
  });
  return response.data;
};

export const put = async <ApiRequest,>(
  url: string,
  data: ApiRequest,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.put<ApiResponse<ApiRequest>>(url, data, {
    ...config,
  });
  return response.data;
};

export const patch = async <ApiRequest,>(
  url: string,
  data: ApiRequest,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.patch<ApiResponse<ApiRequest>>(url, data, {
    ...config,
  });
  return response.data;
};

export const remove = async <ApiRequest,>(
  url: string,
  config?: AxiosRequestConfig<ApiRequest>,
) => {
  const response = await httpClient.delete<ApiResponse<ApiRequest>>(url, {
    ...config,
  });
  return response.data;
};
