import { Navigate, useRoutes } from "react-router-dom";
import { NavigatePath } from "../../shared/utils/constants";
import Login from "../Login";

export const routes = [
  {
    path: "/",
    element: <Navigate replace to={NavigatePath.LOGIN} />,
    roles: [],
    isAuth: false,
  },
  {
    path: "login",
    element: <Login />,
    roles: [],
    isAuth: false,
  },
];

/**
 * 페이지 라우팅
 */
export const LayoutRoute: any = () => {
  const element = useRoutes([...routes]);
  return element;
};
