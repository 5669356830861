export enum NavigatePath {
  /**
   * 메인
   */
  MAIN = "/main",
  /**
   * 로그인
   */
  LOGIN = "/login",
  /**
   * 비밀번호 찾기
   */
  FIND_PASSWORD = "/findPassword",
  /**
   * 프로젝트
   */
  PROJECT = "project",
  /**
   * 씬
   */
  SCENE = "scene",
  /**
   * 캐릭터
   */
  CHARACTER = "character",
}
