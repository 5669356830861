import * as stylex from "@stylexjs/stylex";

import { post } from "../shared/utils/AppService";

const colorStyles = stylex.create({
  container: {
    height: "100vh",
  },
  form: {
    borderColor: "darkred",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
    alignItems: "center",
  },
  input: {
    width: "200px",
    padding: "10px 20px",
    ":focus": {
      outline: "none",
    },
  },
  green: {
    backgroundColor: "lightgreen",
    borderColor: "darkgreen",
  },
});

export default function Login() {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    async function fetchData() {
      const response = await post("/auth/login", {
        email,
        password,
      });
      console.log(response);
    }
    fetchData();
  };

  return (
    <div {...stylex.props(colorStyles["container"])}>
      <form {...stylex.props(colorStyles["form"])} onSubmit={handleSubmit}>
        <input
          type="text"
          name="email"
          placeholder="ID"
          required
          {...stylex.props(colorStyles["input"])}
        />
        <input
          type="password"
          name="password"
          placeholder="PASSWORD"
          required
          {...stylex.props(colorStyles["input"])}
        />
        <button type="submit">log in</button>
      </form>
    </div>
  );
}
