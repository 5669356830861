import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";

export const httpClient: AxiosInstance = axios.create({
  baseURL: "http://localhost:4000/api",
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
} as AxiosRequestConfig);

httpClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const auth = JSON.parse(localStorage.getItem("jwt") || "{}");

    if (auth) {
      // TODO: jwt 로직 처리하기
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
